import React from "react";
import { ZekayContext } from "../ZekayContext";

export default function useZekay() {
  const {
    customers,
    qualifications,
    getTrainingsTemplates,
    getCustomers,
    getCustomer,
    getDoneTrainings,
    getProductsCsv,
    getInvoicesCsv,
    createTraining,
    deleteTraining,
    validateUserQualification,
    updateFile,
    updateTrainingFile,
    deleteTrainingFile,
    getStatDates,
    getExcursionsStatsByYear,
    getExcursionsStatsByMonth,
    getTrainingsByCreditId,
    getTrainingsByStatusCreditId,
    sendMail,
    updateTraining,
    cancelTraining,
    getLogsByTable,
    getActionsLogs,
    getWorkerLogs,
    getWorkerLogsByUserId,
    getCourseTemplate,
    createCourseTemplate,
    updateCourseTemplate,
    createSession,
    updateSession,
    generateSessionPdf,
    updateTrainingTemplate,
    getConfig,
    updateConfig,
    getSystemInfos,
  } = React.useContext(ZekayContext);

  return {
    customers,
    qualifications,
    getTrainingsTemplates,
    getCustomers,
    getCustomer,
    getDoneTrainings,
    getProductsCsv,
    getInvoicesCsv,
    createTraining,
    deleteTraining,
    validateUserQualification,
    updateFile,
    updateTrainingFile,
    deleteTrainingFile,
    getStatDates,
    getExcursionsStatsByYear,
    getExcursionsStatsByMonth,
    getTrainingsByCreditId,
    getTrainingsByStatusCreditId,
    sendMail,
    updateTraining,
    cancelTraining,
    getLogsByTable,
    getActionsLogs,
    getWorkerLogs,
    getWorkerLogsByUserId,
    getCourseTemplate,
    createCourseTemplate,
    updateCourseTemplate,
    createSession,
    updateSession,
    generateSessionPdf,
    updateTrainingTemplate,
    getConfig,
    updateConfig,
    getSystemInfos,
  };
}
