import React from "react";
import {
  Box,
  List,
  ListItem,
  ListItemText,
  CircularProgress,
  TextField,
  Button,
} from "@mui/material";
import { WorkerLog } from "../../../../api/zekay/Zekay.typed";
import useZekay from "../../../../contexts/controllers/useZekay";

const WorkerLogsComponent: React.FC = () => {
  const [logs, setLogs] = React.useState<WorkerLog[]>();
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [searchId, setSearchId] = React.useState<string>("");
  const { getWorkerLogsByUserId } = useZekay();

  const handleSearch = () => {
    setIsLoading(true);
    getWorkerLogsByUserId(searchId)
      .then((l) => setLogs(l))
      .finally(() => setIsLoading(false));
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginBottom: 2,
        }}
      >
        <TextField
          label="Rechercher par ID"
          variant="outlined"
          value={searchId}
          onChange={(e) => setSearchId(e.target.value)}
          sx={{ marginRight: 2 }}
        />
        <Button
          variant="contained"
          color="primary"
          onClick={handleSearch}
          disabled={!searchId}
        >
          Rechercher
        </Button>
      </Box>

      {isLoading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <CircularProgress size={60} />
        </Box>
      ) : (
        <List>
          {logs?.map((log) => (
            <ListItem
              key={log.ID}
              divider
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <ListItemText
                primary={new Date(log.Date).toLocaleString()}
                sx={{ flex: 1, marginRight: 2 }}
              />
              <ListItemText primary={`${log.Status}`} sx={{ flex: 2 }} />
              <ListItemText primary={`${log.Action}`} sx={{ flex: 3 }} />
            </ListItem>
          ))}
        </List>
      )}
    </Box>
  );
};

export default WorkerLogsComponent;